import { createI18n } from 'vue-i18n'
const i18n = createI18n({
  locale: 'ar',
  messages: {
    'en': {
      'Failed to get data please try again later':"Failed to get data please try again later",
      'Full Name':"Full Name",
      'Full Name is required':"Full Name is required",
      'Email':"Email",
      'Email is required':"Email is required",
      'Invalid Email':"Invalid Email",
      'Submit':'Submit',
      'This field is required':'This field is required',
      'Please select at least an option':'Please select at least an option',
      'Answers submitted successfully':'Answers submitted successfully',
      'Failed to submit answer please try agian later':'Failed to submit answer please try agian later',
      'Thank You':'Thank You',
      'Please fill all fields':"Please fill all fields",

    },
    
    'ar': {
      'Failed to get data please try again later':"فشل الحصول على المعلومات الرجاء إعادة المحاولة لاحقاً",
      'Full Name':"الاسم الكامل",
      'Full Name is required':"الرجاء إدخال الاسم الكامل",  
      'Email':"البريد الإلكتروني",
      'Email is required':"الرجاء إدخال البريد الإلكتروني",
      'Invalid Email':"الرجاءالتأكد من صحة البريد الإلكتروني",
      'Submit':"إرسال",
      'This field is required':'هذا الحقل إجباري',
      'Please select an option':'الرجاء إختيار إجابة واحدة',
      'Please select at least an option':'الرجاء إختيار إجابة واحدة على الأقل',
      'Answers submitted successfully':"تم إرسال الإجابات بنجاح",
      'Failed to submit answer please try agian later':"فشل في إرسال الإجابة، يرجى المحاولة مرة أخرى لاحقًا",
      'Thank You':'شكراً لك',

      'Please fill all fields':"يرجى ملء جميع الحقول",
      '':"",
    },
  },
})
export default i18n
