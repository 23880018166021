import { createStore } from 'vuex'

export default createStore({
  state: {
    BaseApiURL:"https://contractingaward.com/api/",
    BaseImageURL:"https://candc.manialab.sa/storage/",
    emailRegex: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
